import React from "react";
import { doc, updateDoc, getFirestore, getDoc, Timestamp } from "firebase/firestore";
import Loading from "./Loading";
import { getTime, reasonsForResolutionList } from "./helpers"

export default function CloseAlarmModal({alarmObj, showModal, setShowModal}) {
  const [notes, setNotes] = React.useState('')
  const [plate, setPlate] = React.useState('')
  const [reactionTime, setReactionTime] = React.useState('not_specified');
  const [guardCount, setGuardCount] = React.useState('not_specified');
  const [signalBars, setSignalBars] = React.useState('not_specified');
  const [reasonForResolution, setReasonForResolution] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  const ref = React.useRef(null)
  const inputRef = React.useRef(null)

  const db = getFirestore();

  const handleTextareaOnFocus = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const handleInputOnFocus = () => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function handleRadioButtons(e) {
    setReasonForResolution(e.target.value)
  }

  async function onUpdateAlarm(e) {
    debugger
    e.preventDefault();
    setShowModal(false);
    const alarmRef = doc(db, "GroupedAlarms", alarmObj.docID);
    const nowTimestamp = Timestamp.fromDate(new Date());

    await updateDoc(alarmRef, {
      reasonForResolution: reasonForResolution,
      notes: (notes || ""),
      plate: (plate || ""),
      reactionTime: (reactionTime || 'not_specified'),
      guardCount: (guardCount || 'not_specified'),
      signalBars: (signalBars || 'not_specified'),
      resolvedUpdatedAt: nowTimestamp,
      resolvedAt: alarmObj.resolvedAt ? alarmObj.resolvedAt : nowTimestamp,
    });
  }

  React.useEffect(() => {
    setNotes('')
    async function getAlarm() {
      const alarmRef = doc(db, "GroupedAlarms", alarmObj.docID);
      const docSnap = await getDoc(alarmRef);
      if (docSnap.exists()) {
        setNotes(docSnap.data().notes);
        setReasonForResolution(docSnap.data().reasonForResolution);
        setPlate(docSnap.data().plate);
        setReactionTime(docSnap.data().reactionTime);
        setGuardCount(docSnap.data().guardCount);
        setSignalBars(docSnap.data().signalBars);
        setLoading(false);
      }
    }
    getAlarm();
  }, [alarmObj, db])

  return (
    showModal && (
      <div className="fixed inset-0 overflow-y-auto flex items-center justify-center h-screen w-screen">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="relative px-8 py-10 bg-white rounded-md min-w-[500px]">
          <button
            type="button"
            className="absolute top-4 right-4 text-gray-600"
            onClick={() => setShowModal(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div>
            <div className="mb-6 flex">
              <div className="text-2xl mr-2 pt-2">🚨</div>
              <div>
                <h3 className="font-bold text-2xl text-gray-700">{`${alarmObj.zoneName}`}</h3>
                <div className="flex text-gray-400 text-sm items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  {`Duración ${getTime(alarmObj.duration)}`}
                </div>
              </div>
            </div>

            {loading ? (
              <div className="flex justify-center items-center py-20">
                <div className="w-10"><Loading size="10" /></div>
              </div>
            ) : (
              <form onSubmit={e => onUpdateAlarm(e)}>
                  <div className="mb-4">
                    <p className="mb-2 font-bold">
                      Selecciona qué pasó con este incidente.
                    </p>
                    <div className="flex justify-between overflow-hidden rounded-lg">
                    {reasonsForResolutionList.map(item => (
                      <label className={`hover:bg-blue-100 relative flex font-bold items-center border border-white w-1/3 p-4 ${reasonForResolution === item.value ? 'bg-blue-400 hover:bg-blue-400 text-white' : 'bg-blue-50 text-gray-600'}`} key={item.value}>
                        <input
                          type="radio"
                          name="reason_for_resolution"
                          value={item.value}
                          className="mr-2 opacity-0 absolute inset-0 cursor-pointer"
                          checked={reasonForResolution === item.value}
                          onChange={e => handleRadioButtons(e)}
                        />
                        {reasonForResolution === item.value && (
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 inline-block mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                          </svg>
                        )}
                        {item.label}
                      </label>
                    ))}
                  </div>
                  </div>

                  <div className="mb-4">
                    <p className="mb-2 font-bold">
                      Patente de vehículo sospechoso.
                    </p>
                    <input
                      type="text"
                      placeholder="AAAA11"
                      value={plate || ''}
                      onChange={e => setPlate(e.target.value.toUpperCase())}
                      className="input-text"
                      ref={inputRef}
                      onFocus={handleInputOnFocus}
                    />
                  </div>

                  <div className="mb-4">
                    <p className="mb-2 font-bold">Tiempo de reacción</p>
                    <select
                      value={reactionTime}
                      onChange={(e) => setReactionTime(e.target.value)}
                    >
                      <option value="not_specified">No especificado</option>
                      <option value="nobody_went">Nadie fue</option>
                      <option value="Menos de 5 min">Menos de 5 min</option>
                      <option value="Más de 5 min">Más de 5 min</option>
                    </select>
                  </div>

                  <div className="mb-4">
                    <p className="mb-2 font-bold">Cantidad de guardias</p>
                    <select
                      value={guardCount}
                      onChange={(e) => setGuardCount(e.target.value)}
                    >
                      <option value="not_specified">No especificado</option>
                      <option value="nobody_went">Nadie fue</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>

                  <div className="mb-4">
                    <p className="mb-2 font-bold">Barras de señal</p>
                    <select
                      value={signalBars}
                      onChange={(e) => setSignalBars(e.target.value)}
                    >
                      <option value="not_specified">No especificado</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </div>

                  <div className="mb-4">
                    <p className="mb-2 font-bold">
                      Comentarios.
                    </p>
                    <textarea
                      className="input-text"
                      name="notes"
                      rows="5"
                      placeholder="Agrega aquí cualquier información relevante como por ejemplo: Fue necesario contactar con carabineros."
                      value={notes || ""}
                      onChange={e => setNotes(e.target.value)}
                      ref={ref}
                      onFocus={handleTextareaOnFocus}
                    />
                  </div>

                  <button type="submit" className="btn w-full text-lg">
                    Guardar y cerrar
                  </button>
                </form>
            )}
          </div>
        </div>
      </div>
    )
  )
}
